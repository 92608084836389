define("budgeta/utils/loader-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateVisibility = void 0;
  var updateVisibility = _exports.updateVisibility = function updateVisibility() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      showOverlay = _ref.showOverlay,
      showLoader = _ref.showLoader;
    Ember.$("#overlay").toggleClass("hidden", !showOverlay);
    Ember.$("#overlay").toggleClass("with-background", showOverlay);
    Ember.$("header .busy").toggle(showLoader);
  };
});