define("budgeta/components/budgeta-progress-bar", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":budgeta-progress-bar", "ignoreBar:ignore-bar", "smallValue:small-value"],
    value: Ember.computed("maxSteps", "step", function () {
      return Math.round(this.get("step") / this.get("maxSteps") * 100);
    }),
    smallValue: Ember.computed("value", function () {
      return this.get("value") > 0 && this.get("value") < 11;
    }),
    title: Ember.computed("isMain", "value", "ignoreBar", "ignoreExtraTitle", function () {
      var title = "".concat(intl.t("budget-structure.steps-completed"), ": ").concat(this.get("step"), "/").concat(this.get("maxSteps"));
      if (!this.get("ignoreBar") && !this.get("ignoreExtraTitle")) {
        title += "!";
        if (this.get("value") <= 30) {
          title += " ".concat(intl.t("budget-structure.great-start"));
        } else if (this.get("value") <= 50) {
          title += " ".concat(intl.t("budget-structure.well-done"));
        } else if (this.get("value") <= 80) {
          title += " ".concat(intl.t("budget-structure.excellent"));
        } else if (this.get("value") < 100) {
          title += " ".concat(intl.t("budget-structure.almost-there"));
        } else {
          title = " ".concat(intl.t("budget-structure.congratulations"));
        }
      } else if (this.get("value") === 100) {
        title = intl.t("budget-structure.done");
      }
      if (!this.get("maxSteps")) {
        return intl.t("budget-structure.removed-from-guide");
      }
      return title;
    }),
    style: Ember.computed("value", function () {
      return Ember.String.htmlSafe("width: ".concat(this.get("value") || 0, "%;"));
    })
  });
});