define("budgeta/services/setup-guide", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/budget-utils", "budgeta/utils/dimensions"], function (_exports, _emberServiceContainer, _const, _budgetUtils, _dimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var Group = Ember.Object.extend({
    title: "",
    icon: "",
    missions: null,
    missionStatuses: Ember.computed("missions.@each.status", function () {
      return this.get("missions").map(function (mission) {
        return mission.get("status");
      });
    }),
    missionActives: Ember.computed("missions.@each.active", function () {
      return this.get("missions").map(function (mission) {
        return mission.get("active");
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get("missions")) {
        this.set("missions", []);
      }

      // computed properties are called to ensure, that they will be calculated for dependent keys in front-end/app/controllers/setup-guide-modal.js
      this.get("missionActives");
      this.get("missionStatuses");
    }
  });
  var _default = _exports.default = Ember.Service.extend({
    budgetService: Ember.inject.service(),
    cache: Ember.inject.service(),
    permissions: Ember.inject.service(),
    selectedRoot: Ember.computed.alias("budgetService.selectedRoot"),
    setupGuideOptions: Ember.computed.alias("budgetService.selectedRoot.setupGuideOptions"),
    store: Ember.inject.service(),
    recalcGuide: undefined,
    buildHeadcount: function buildHeadcount() {
      var selectedRoot = this.get("selectedRoot"),
        budgetStartDate = new Date(selectedRoot.get("budgetAttributes.startDate")),
        employees = this.get("budgetLines.employees"),
        employeesMulti = this.get("budgetLines.employeeMulti"),
        contractors = this.get("budgetLines.contractors"),
        setupGuideOptions = this.get("setupGuideOptions"),
        employeesNumber = 0,
        employeesWithStartDateNumber = 0,
        newEmployeesNumber = 0,
        headcountGuide = Group.create({
          title: intl.t("general.title_1695611597579"),
          icon: "face",
          missions: []
        });
      employees.forEach(function (employee) {
        employeesNumber++;
        if (employee.get("budgetAttributes.startDate") && budgetStartDate.isBefore(new Date(employee.get("budgetAttributes.startDate")))) {
          employeesWithStartDateNumber++;
        }
      });
      employeesMulti.forEach(function (multiEmp) {
        newEmployeesNumber += (multiEmp.get("numOfEmployees") || 0) * 1;
      });
      var employeesMission = {
        title: intl.t("general.title_1695611599982"),
        status: "incomplete",
        optionName: "employees",
        active: setupGuideOptions.findBy("optionName", "employees").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254145"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254651"),
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      if (employeesNumber > 0 || newEmployeesNumber > 0) {
        employeesMission.subtitleComplete = intl.t("general.total_employee_count_1695953254651", {
          totalEmployeeCount: employeesNumber + newEmployeesNumber
        });
        if (employeesMission.status === "incomplete") {
          employeesMission.status = "complete";
        }
      } else {
        employeesMission.status = "incomplete";
      }
      var newHiresMission = {
        title: intl.t("general.title_1695611597653"),
        status: "incomplete",
        optionName: "newHires",
        active: setupGuideOptions.findBy("optionName", "newHires").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254134"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254640"),
        helpLink: "https://www.youtube.com/watch?v=2kwaIk6Yp2E",
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      if (newEmployeesNumber > 0 || employeesWithStartDateNumber > 0) {
        newHiresMission.subtitleComplete = intl.t("general.total_new_hires_count_1695953254651", {
          newHiresCount: newEmployeesNumber + employeesWithStartDateNumber
        });
        if (newHiresMission.status === "incomplete") {
          newHiresMission.status = "complete";
        }
      } else {
        newHiresMission.status = "incomplete";
      }
      var contractorsMission = {
        title: intl.t("general.title_1695611599961"),
        status: "incomplete",
        optionName: "contractors",
        active: setupGuideOptions.findBy("optionName", "contractors").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254124"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254630"),
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      if (contractors.length > 0) {
        contractorsMission.subtitleComplete = intl.t("general.total_contractors_count_1695953254651", {
          contractorsCount: contractors.length
        });
        if (contractorsMission.status === "incomplete") {
          contractorsMission.status = "complete";
        }
      } else {
        contractorsMission.status = "incomplete";
      }
      headcountGuide.get("missions").pushObjects([employeesMission, newHiresMission, contractorsMission]);
      return headcountGuide;
    },
    buildTeam: function buildTeam() {
      var teamGuide = Group.create({
          title: intl.t("general.title_1695611599951"),
          icon: "share",
          missions: []
        }),
        setupGuideOptions = this.get("setupGuideOptions");
      var sharesMission = {
        title: intl.t("general.title_1695611599940"),
        status: "incomplete",
        optionName: "budgetShares",
        active: setupGuideOptions.findBy("optionName", "budgetShares").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254114"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254619"),
        helpLink: "https://help.intacct-planning.com/intacct-planning/SIP_Topics/Share_budget.htm?Highlight=share%20",
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      var shares = this.get("budgetLines.shares");
      if (shares && shares.length) {
        sharesMission.subtitleComplete = shares.length === 1 ? intl.t("general.total_budget_shares_count_1695953254651") : intl.t("general.total_budget_shares_count_1695953254652", {
          sharesCount: shares.length
        });
        if (sharesMission.status === "incomplete") {
          sharesMission.status = "complete";
        }
      } else {
        sharesMission.status = "incomplete";
      }
      teamGuide.get("missions").pushObject(sharesMission);
      return teamGuide;
    },
    buildScenarios: function buildScenarios() {
      var scenariosGuide = Group.create({
          title: intl.t("general.title_1695611599920"),
          icon: "featured_play_list",
          missions: []
        }),
        setupGuideOptions = this.get("setupGuideOptions");
      var scenariosMission = {
        title: intl.t("general.title_1695611599920"),
        status: "incomplete",
        optionName: "scenarios",
        active: setupGuideOptions.findBy("optionName", "scenarios").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254102"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254608"),
        helpLink: "https://www.youtube.com/watch?v=aWgm7TsGizg",
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      var scenarios = this.get("budgetService.scenarios.length") || 0;
      if (scenarios > 0) {
        scenariosMission.subtitleComplete = intl.t("general.total_scenarios_count_1695953254651", {
          scenariosCount: scenarios
        });
        if (scenariosMission.status === "incomplete") {
          scenariosMission.status = "complete";
        }
      } else {
        scenariosMission.status = "incomplete";
      }
      scenariosGuide.get("missions").pushObject(scenariosMission);
      return scenariosGuide;
    },
    buildDashboard: function buildDashboard() {
      var dashboardGuide = Group.create({
          title: intl.t("general.title_1695611599908"),
          icon: "chart",
          missions: []
        }),
        setupGuideOptions = this.get("setupGuideOptions");
      var interactiveMission = {
        title: intl.t("general.title_1695611599898"),
        status: "incomplete",
        optionName: "interactiveDashboard",
        active: setupGuideOptions.findBy("optionName", "interactiveDashboard").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254092"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254597"),
        helpLink: "https://www.youtube.com/watch?v=eV28wAdT_j4",
        id: _const.default.generateUUID()
      };
      var interactives = (Ember.isArray(this.get("selectedRoot.dashboardList")) ? this.get("selectedRoot.dashboardList") : []).filterBy("type", "interactive");
      if (interactives.length > 0) {
        interactiveMission.subtitleComplete = intl.t("general.total_interactive_dashboards_count_1695953254651", {
          interactiveDashboardsCount: interactives.length
        });
        if (interactiveMission.status === "incomplete") {
          interactiveMission.status = "complete";
        }
      } else {
        interactiveMission.status = "incomplete";
      }
      var widgetsMission = {
        title: intl.t("general.title_1695611599887"),
        status: "incomplete",
        optionName: "widgets",
        active: setupGuideOptions.findBy("optionName", "widgets").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254082"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254587"),
        id: _const.default.generateUUID(),
        helpLink: "https://www.youtube.com/watch?v=4vB2JminRzc",
        gotoAction: true
      };
      if (Ember.get(setupGuideOptions.findBy("optionName", "widgets"), "data") > 0) {
        widgetsMission.subtitleComplete = intl.t("general.total_widgets_count_1695953254651", {
          widgetsCount: Ember.get(setupGuideOptions.findBy("optionName", "widgets"), "data")
        });
        if (widgetsMission.status === "incomplete") {
          widgetsMission.status = "complete";
        }
      } else {
        widgetsMission.status = "incomplete";
      }
      dashboardGuide.get("missions").pushObject(widgetsMission);
      dashboardGuide.get("missions").pushObject(interactiveMission);
      return dashboardGuide;
    },
    buildExporing: function buildExporing() {
      var exportingGuide = Group.create({
          title: intl.t("general.title_1695611599876"),
          icon: "create_report",
          missions: []
        }),
        setupGuideOptions = this.get("setupGuideOptions");
      var excelMission = {
        title: intl.t("general.title_1695611599866"),
        status: "incomplete",
        optionName: "excelReports",
        active: setupGuideOptions.findBy("optionName", "excelReports").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254071"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254577"),
        id: _const.default.generateUUID(),
        helpLink: "https://www.youtube.com/watch?v=grF6VoJ-kfA",
        gotoAction: true
      };
      if (Ember.get(setupGuideOptions.findBy("optionName", "excelReports"), "data") > 0) {
        excelMission.subtitleComplete = intl.t("general.total_excel_reports_count_1695953254651", {
          excelReportsCount: Ember.get(setupGuideOptions.findBy("optionName", "excelReports"), "data")
        });
        if (excelMission.status === "incomplete") {
          excelMission.status = "complete";
        }
      } else {
        excelMission.status = "incomplete";
      }
      exportingGuide.get("missions").pushObject(excelMission);
      if (!this.get("isBasic")) {
        var pdfMission = {
          title: intl.t("general.title_1695611599855"),
          status: "incomplete",
          optionName: "pdfReports",
          active: setupGuideOptions.findBy("optionName", "pdfReports").active,
          subtitleComplete: intl.t("general.subtitleComplete_1695953254061"),
          subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254567"),
          id: _const.default.generateUUID(),
          helpLink: "https://www.youtube.com/watch?v=grF6VoJ-kfA",
          gotoAction: true
        };
        if (Ember.get(setupGuideOptions.findBy("optionName", "pdfReports"), "data") > 0) {
          pdfMission.subtitleComplete = intl.t("general.total_pdf_reporting_packages_count_1695953254651", {
            pdfReportingCount: Ember.get(setupGuideOptions.findBy("optionName", "pdfReports"), "data")
          });
          if (pdfMission.status === "incomplete") {
            pdfMission.status = "complete";
          }
        } else {
          pdfMission.status = "incomplete";
        }
        exportingGuide.get("missions").pushObject(pdfMission);
      }
      return exportingGuide;
    },
    buildModels: function buildModels() {
      var modelsGuide = Group.create({
          title: intl.t("general.title_1695611597516"),
          icon: "function_sum",
          missions: []
        }),
        setupGuideOptions = this.get("setupGuideOptions");
      var modelsMission = {
        title: intl.t("general.title_1695611597516"),
        status: "incomplete",
        optionName: "models",
        active: setupGuideOptions.findBy("optionName", "models").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254050"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254557"),
        helpLink: "https://www.youtube.com/watch?v=XQ2PsarNPCk",
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      var models = this.get("budgetLines.groupModel");
      var modelChartsMission = {
        title: intl.t("general.title_1695611599824"),
        status: "incomplete",
        optionName: "modelCharts",
        active: setupGuideOptions.findBy("optionName", "modelCharts").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254039"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254547"),
        helpLink: "https://www.youtube.com/watch?v=XQ2PsarNPCk",
        id: _const.default.generateUUID()
      };
      var modelCharts = 0;
      if (models.length > 0) {
        modelsMission.subtitleComplete = intl.t("general.total_models_count_1695953254651", {
          modelsCount: models.length
        });
        if (modelsMission.status === "incomplete") {
          modelsMission.status = "complete";
        }
        models.forEach(function (model) {
          modelCharts += model.get("isOnDashboard.length") || 0;
        });
      } else {
        modelsMission.status = "incomplete";
      }
      if (modelCharts > 0) {
        modelChartsMission.subtitleComplete = intl.t("general.total_charts_count_1695953254651", {
          chartsCount: modelCharts
        });
        if (modelChartsMission.status === "incomplete") {
          modelChartsMission.status = "complete";
        }
      } else {
        modelChartsMission.status = "incomplete";
      }
      modelsGuide.get("missions").pushObject(modelsMission);
      modelsGuide.get("missions").pushObject(modelChartsMission);
      var modelsLibraryMission = {
        title: intl.t("general.title_1695611599813"),
        status: "incomplete",
        optionName: "modelsLibrary",
        active: setupGuideOptions.findBy("optionName", "modelsLibrary").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254029"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254537"),
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      if (Ember.get(setupGuideOptions.findBy("optionName", "modelsLibrary"), "data") > 0) {
        modelsLibraryMission.subtitleComplete = intl.t("general.total_models_from_library_count_1695953254651", {
          modelsFromLibraryCount: Ember.get(setupGuideOptions.findBy("optionName", "modelsLibrary"), "data")
        });
        if (modelsLibraryMission.status === "incomplete") {
          modelsLibraryMission.status = "complete";
        }
      } else {
        modelsLibraryMission.status = "incomplete";
      }
      modelsGuide.get("missions").pushObject(modelsLibraryMission);
      return modelsGuide;
    },
    buildActuals: function buildActuals() {
      var actualsGuide = Group.create({
          title: intl.t("general.title_1695611599802"),
          icon: "event_note",
          missions: []
        }),
        setupGuideOptions = this.get("setupGuideOptions");
      var actualsMission = {
        title: intl.t("general.title_1695611599791"),
        status: "incomplete",
        optionName: "actuals",
        active: setupGuideOptions.findBy("optionName", "actuals").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254018"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254526"),
        id: _const.default.generateUUID(),
        helpLink: "https://help.intacct-planning.com/intacct-planning/SIP_Topics/Create_your_plan.htm?tocpath=Create%20your%20plan%7C_____0",
        gotoAction: true
      };
      var selectedRoot = this.get("selectedRoot");
      var actuals = _budgetUtils.default.getTransactionsRecursiveFaster(selectedRoot, this.get("cache"));
      if (actuals && actuals.length) {
        actualsMission.status = "complete";
      }
      actualsGuide.get("missions").pushObject(actualsMission);
      return actualsGuide;
    },
    buildSettings: function buildSettings() {
      var settingsGuide = Group.create({
          title: intl.t("general.title_1695611599780"),
          icon: "settings",
          missions: []
        }),
        budgetLines = this.get("budgetLines"),
        setupGuideOptions = this.get("setupGuideOptions");
      var openingBalancesMission = {
        title: intl.t("general.title_1695611599769"),
        status: "incomplete",
        optionName: "openingBalances",
        active: setupGuideOptions.findBy("optionName", "openingBalances").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953254007"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254516"),
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      var advancedBenefitsMission = {
        title: intl.t("general.title_1695611599758"),
        status: "incomplete",
        optionName: "advancedBenefits",
        active: setupGuideOptions.findBy("optionName", "advancedBenefits").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953253997"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254505"),
        helpLink: _const.default.HELP_URL + "/intacct-planning/SIP_Topics/Employees_advanced_benefits.htm?tocpath=Utilize%20your%20plan%7CWork%20with%20employee%20data%7C_____2",
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      var selectedRoot = this.get("selectedRoot"),
        cashBalanceByCurrency = selectedRoot.get("cashBalanceByCurrency") || [],
        cashBalance = selectedRoot.get("cashBalance");
      if (cashBalanceByCurrency && cashBalanceByCurrency.length) {
        cashBalanceByCurrency.forEach(function (cash) {
          if (cash.value && !Ember.isEmpty(cash.value)) {
            openingBalancesMission.status = "complete";
          }
        });
      } else if (cashBalance) {
        openingBalancesMission.status = "complete";
      }
      if (budgetLines && budgetLines.advancedBenefits && budgetLines.advancedBenefits.length) {
        advancedBenefitsMission.status = "complete";
      }
      settingsGuide.get("missions").pushObject(openingBalancesMission);
      settingsGuide.get("missions").pushObject(advancedBenefitsMission);
      return settingsGuide;
    },
    buildDimensions: function buildDimensions() {
      var dimensionsGuide = Group.create({
          title: intl.t("general.title_1695611599747"),
          icon: "extension",
          missions: []
        }),
        store = this.get("store"),
        setupGuideOptions = this.get("setupGuideOptions");
      var dimensionsInBudgetLinesMission = {
        title: intl.t("general.title_1695611599737"),
        status: "incomplete",
        optionName: "dimensionsInBudgetLines",
        active: setupGuideOptions.findBy("optionName", "dimensionsInBudgetLines").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953253986"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254494"),
        id: _const.default.generateUUID(),
        gotoAction: false
      };
      var customisedDimensionsMission = {
        title: intl.t("general.title_1695611599726"),
        status: "incomplete",
        optionName: "customisedDimensions",
        active: setupGuideOptions.findBy("optionName", "customisedDimensions").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953253976"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254484"),
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      var dimensions = this.get("selectedRoot.dimensions") || [];
      var foundDimension = false;
      if (dimensions) {
        dimensions.forEach(function (d) {
          if (!foundDimension) {
            if (_dimensions.default.isDimensionUsedValues(d, store, undefined, true)) {
              foundDimension = true;
            }
          }
        });
      }
      if (foundDimension) {
        dimensionsInBudgetLinesMission.status = "complete";
      }
      if (dimensions && dimensions.filter(function (d) {
        return !Ember.get(d, "type");
      }).length) {
        customisedDimensionsMission.status = "complete";
      }
      dimensionsGuide.get("missions").pushObject(dimensionsInBudgetLinesMission);
      dimensionsGuide.get("missions").pushObject(customisedDimensionsMission);
      return dimensionsGuide;
    },
    buildBudgetStructure: function buildBudgetStructure() {
      var budgetStructure = Group.create({
          title: intl.t("general.title_1695611599715"),
          icon: "structure",
          missions: []
        }),
        setupGuideOptions = this.get("setupGuideOptions"),
        budgetLines = this.get("budgetLines"),
        emptyGroups = this.get("budgetLines.emptyGroups");
      var checkMission = function checkMission(groups, mission, condition) {
        if (emptyGroups && emptyGroups.length) {
          groups.forEach(function (group) {
            emptyGroups.forEach(function (emptyGroup) {
              if (_const.default.isAncestor(group, emptyGroup) && (!condition || emptyGroup.get(condition))) {
                mission.status = "incomplete";
                mission.emptyGroups.push(emptyGroup);
              }
            });
          });
        }
      };
      if (budgetLines.revenues && budgetLines.revenues.length) {
        var revenueMission = {
          title: intl.t("general.title_1695611597810"),
          status: "complete",
          optionName: "revenue",
          active: setupGuideOptions.findBy("optionName", "revenue") && setupGuideOptions.findBy("optionName", "revenue").active,
          subtitleComplete: intl.t("general.subtitleComplete_1695953253965"),
          subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254473"),
          id: _const.default.generateUUID(),
          gotoActionEmptyGroups: true,
          emptyGroups: []
        };
        budgetStructure.showSummary = true;
        checkMission(budgetLines.revenues, revenueMission);
        budgetStructure.get("missions").pushObject(revenueMission);
      }
      if (budgetLines.costOfRevenues && budgetLines.costOfRevenues.length) {
        var costOfRevenueMission = {
          title: intl.t("general.title_1695611599696"),
          status: "complete",
          optionName: "costOfRevenue",
          active: setupGuideOptions.findBy("optionName", "costOfRevenue") && setupGuideOptions.findBy("optionName", "costOfRevenue").active,
          subtitleComplete: intl.t("general.subtitleComplete_1695953253954"),
          subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254463"),
          id: _const.default.generateUUID(),
          gotoActionEmptyGroups: true,
          emptyGroups: []
        };
        checkMission(budgetLines.costOfRevenues, costOfRevenueMission);
        budgetStructure.get("missions").pushObject(costOfRevenueMission);
      }
      if (budgetLines.simpleOpex && budgetLines.simpleOpex.length) {
        var operationalExpensesMission = {
          title: intl.t("general.title_1695611599684"),
          status: "complete",
          optionName: "operationalExpenses",
          active: setupGuideOptions.findBy("optionName", "operationalExpenses") && setupGuideOptions.findBy("optionName", "operationalExpenses").active,
          subtitleComplete: intl.t("general.subtitleComplete_1695953253943"),
          subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254453"),
          id: _const.default.generateUUID(),
          gotoActionEmptyGroups: true,
          emptyGroups: []
        };
        checkMission(budgetLines.simpleOpex, operationalExpensesMission);
        budgetStructure.get("missions").pushObject(operationalExpensesMission);
      }
      if (budgetLines.otherIncomeAndExpenses && budgetLines.otherIncomeAndExpenses.length) {
        var otherIncomeAndExpensesMission = {
          title: intl.t("general.title_1695611599673"),
          status: "complete",
          optionName: "otherIncomeAndExpenses",
          active: setupGuideOptions.findBy("optionName", "otherIncomeAndExpenses").active,
          subtitleComplete: intl.t("general.subtitleComplete_1695953253932"),
          subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254442"),
          id: _const.default.generateUUID(),
          gotoActionEmptyGroups: true,
          emptyGroups: []
        };
        checkMission(budgetLines.otherIncomeAndExpenses, otherIncomeAndExpensesMission);
        budgetStructure.get("missions").pushObject(otherIncomeAndExpensesMission);
      }
      if (budgetLines.subsidiaries && budgetLines.subsidiaries.length) {
        var subsidiariesMission = {
          title: intl.t("general.title_1695611599662"),
          status: "complete",
          optionName: "subsidiaries",
          active: setupGuideOptions.findBy("optionName", "otherIncomeAndExpenses").active,
          subtitleComplete: intl.t("general.subtitleComplete_1695953253921"),
          subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254431"),
          id: _const.default.generateUUID(),
          gotoActionEmptyGroups: true,
          emptyGroups: []
        };
        checkMission(budgetLines.subsidiaries, subsidiariesMission);
        budgetStructure.get("missions").pushObject(subsidiariesMission);
      }
      return budgetStructure;
    },
    buildProfile: function buildProfile() {
      var profileGuide = Group.create({
          title: intl.t("general.title_1695611599652"),
          icon: "account",
          missions: []
        }),
        setupGuideOptions = this.get("setupGuideOptions");
      var profileMission = {
        title: intl.t("general.title_1695611599640"),
        status: "incomplete",
        optionName: "userProfile",
        active: setupGuideOptions.findBy("optionName", "userProfile").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953253910"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254421"),
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      var currentUser = this.get("permissions.currentUser");
      if (currentUser && Ember.get(currentUser, "firstName") && Ember.get(currentUser, "lastName") && Ember.get(currentUser, "position") && Ember.get(currentUser, "company") && Ember.get(currentUser, "phoneNumber")) {
        profileMission.status = "complete";
      } else {
        profileMission.status = "incomplete";
      }
      profileGuide.get("missions").pushObject(profileMission);
      return profileGuide;
    },
    buildVersions: function buildVersions() {
      var versionsGuide = Group.create({
          title: intl.t("general.title_1695611599630"),
          icon: "restore",
          missions: []
        }),
        setupGuideOptions = this.get("setupGuideOptions");
      var baseMission = {
        title: intl.t("general.title_1695611599619"),
        status: "incomplete",
        optionName: "versionsBaseBudget",
        active: setupGuideOptions.findBy("optionName", "versionsBaseBudget").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953253900"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254410"),
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      var baseBudget = this.get("budgetService.selectedRoot.versions") && this.get("budgetService.selectedRoot.versions").findBy("type", "base");
      baseMission.status = baseBudget ? "complete" : "incomplete";
      versionsGuide.get("missions").pushObject(baseMission);
      var forecastMission = {
        title: intl.t("general.title_1695611599609"),
        status: "incomplete",
        optionName: "versionsForecast",
        active: setupGuideOptions.findBy("optionName", "versionsForecast").active,
        subtitleComplete: intl.t("general.subtitleComplete_1695953253889"),
        subtitleIncomplete: intl.t("general.subtitleIncomplete_1695953254399"),
        id: _const.default.generateUUID(),
        gotoAction: true
      };
      var forecast = this.get("budgetService.selectedRoot.versions") && this.get("budgetService.selectedRoot.versions").findBy("type", "forecast");
      forecastMission.status = forecast ? "complete" : "incomplete";
      versionsGuide.get("missions").pushObject(forecastMission);
      return versionsGuide;
    },
    initService: function initService() {
      var setupGuideOptions = this.get("setupGuideOptions");
      if (!setupGuideOptions || !Ember.isArray(setupGuideOptions)) {
        this.set("setupGuideOptions", []);
        setupGuideOptions = this.get("setupGuideOptions");
      }
      if (!setupGuideOptions.findBy("optionName", "versionsBaseBudget")) {
        setupGuideOptions.pushObject({
          optionName: "versionsBaseBudget",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "versionsForecast")) {
        setupGuideOptions.pushObject({
          optionName: "versionsForecast",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "userProfile")) {
        setupGuideOptions.pushObject({
          optionName: "userProfile",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "employees")) {
        setupGuideOptions.pushObject({
          optionName: "employees",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "newHires")) {
        setupGuideOptions.pushObject({
          optionName: "newHires",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "contractors")) {
        setupGuideOptions.pushObject({
          optionName: "contractors",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "budgetShares")) {
        setupGuideOptions.pushObject({
          optionName: "budgetShares",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "scenarios")) {
        setupGuideOptions.pushObject({
          optionName: "scenarios",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "widgets")) {
        setupGuideOptions.pushObject({
          optionName: "widgets",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "interactiveDashboard")) {
        setupGuideOptions.pushObject({
          optionName: "interactiveDashboard",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "models")) {
        setupGuideOptions.pushObject({
          optionName: "models",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "modelCharts")) {
        setupGuideOptions.pushObject({
          optionName: "modelCharts",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "modelsLibrary")) {
        setupGuideOptions.pushObject({
          optionName: "modelsLibrary",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "excelReports")) {
        setupGuideOptions.pushObject({
          optionName: "excelReports",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "pdfReports")) {
        setupGuideOptions.pushObject({
          optionName: "pdfReports",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "openingBalances")) {
        setupGuideOptions.pushObject({
          optionName: "openingBalances",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "advancedBenefits")) {
        setupGuideOptions.pushObject({
          optionName: "advancedBenefits",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "actuals")) {
        setupGuideOptions.pushObject({
          optionName: "actuals",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "revenue")) {
        setupGuideOptions.pushObject({
          optionName: "revenue",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "costOfRevenue")) {
        setupGuideOptions.pushObject({
          optionName: "costOfRevenue",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "operationalExpenses")) {
        setupGuideOptions.pushObject({
          optionName: "operationalExpenses",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "otherExpensesGroups")) {
        setupGuideOptions.pushObject({
          optionName: "otherExpensesGroups",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "otherIncomeAndExpenses")) {
        setupGuideOptions.pushObject({
          optionName: "otherIncomeAndExpenses",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "dimensionsInBudgetLines")) {
        setupGuideOptions.pushObject({
          optionName: "dimensionsInBudgetLines",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "customisedDimensions")) {
        setupGuideOptions.pushObject({
          optionName: "customisedDimensions",
          active: true,
          data: 0
        });
      }
      if (!setupGuideOptions.findBy("optionName", "Subsidiaries")) {
        setupGuideOptions.pushObject({
          optionName: "Subsidiaries",
          active: true,
          data: 0
        });
      }
    },
    isBasic: Ember.computed("permissions.isAdmin", "permissions.isUnlimited", function () {
      var isAdmin = this.get("permissions.isAdmin");
      var isUnlimited = this.get("permissions.isUnlimited");
      return !(isAdmin || isUnlimited);
    }),
    guideMissions: Ember.computed("selectedRoot", "selectedRoot.versions.@each.type", "recalcGuide", "budgetService.scenarios.length", "budgetLines", function () {
      var guideMissions = {
        groups: []
      };
      if (this.get("setupGuideOptions")) {
        this.initService();
        guideMissions.groups.pushObject(this.buildBudgetStructure());
        guideMissions.groups.pushObject(this.buildHeadcount());
        guideMissions.groups.pushObject(this.buildTeam());
        if (this.get("permissions.canUseScenarios")) {
          guideMissions.groups.pushObject(this.buildScenarios());
        }
        guideMissions.groups.pushObject(this.buildExporing());
        if (this.get("permissions.canCustomizeDashboard")) {
          guideMissions.groups.pushObject(this.buildDashboard());
        }
        guideMissions.groups.pushObject(this.buildModels());
        if (this.get("permissions.canViewActuals")) {
          guideMissions.groups.pushObject(this.buildActuals());
        }
        if (this.get("permissions.canUseVersions")) {
          guideMissions.groups.pushObject(this.buildVersions());
        }
        guideMissions.groups.pushObject(this.buildSettings());
        guideMissions.groups.pushObject(this.buildDimensions());
        guideMissions.groups.pushObject(this.buildProfile());
      }
      return guideMissions;
    }),
    budgetLines: Ember.computed("selectedRoot", "recalcGuide", "store", function () {
      var budgetLines = {
        employees: [],
        employeeMulti: [],
        contractors: [],
        groupModel: [],
        emptyGroups: [],
        companyBudget: [],
        revenues: [],
        subsidiaries: [],
        costOfRevenues: [],
        simpleOpex: [],
        otherIncomeAndExpenses: [],
        advancedBenefits: [],
        shares: []
      };
      var budgetType;
      var budgetFirstLevel = function budgetFirstLevel(budget) {
        budget.get("children").forEach(function (child) {
          if (child.get("budgetType") === "group-revenue") {
            budgetLines.revenues.pushObject(child);
          } else if (child.get("budgetType") === "group-expense-costofsale") {
            budgetLines.costOfRevenues.pushObject(child);
          } else if (child.get("budgetType") === "group-expense") {
            budgetLines.simpleOpex.pushObject(child);
          } else if (child.get("budgetType") === "group-company") {
            budgetLines.subsidiaries.pushObject(child);
          } else if (child.get("budgetType") === "group-expense-other") {
            budgetLines.otherIncomeAndExpenses.pushObject(child);
          }
        });
      };
      this.get("store").peekAll("budget").forEach(function (b) {
        budgetType = b.get("budgetType");
        if (budgetType === "employee") {
          budgetLines.employees.pushObject(b);
        } else if (budgetType === "employee-multi") {
          budgetLines.employeeMulti.pushObject(b);
        } else if (budgetType === "contractor") {
          budgetLines.contractors.pushObject(b);
        } else if (budgetType === "group-model") {
          budgetLines.groupModel.pushObject(b);
        } else if (budgetType === "group-company" || budgetType === "group-company-intacct") {
          budgetFirstLevel(b);
        } else if (budgetType === "expense-benefit") {
          budgetLines.advancedBenefits.pushObject(b);
        }
        if (b.get("isEmptyGroup")) {
          budgetLines.emptyGroups.pushObject(b);
        }
        if (Ember.isArray(b.get("sharingTokens"))) {
          budgetLines.shares.addObjects(b.get("sharingTokens").map(function (s) {
            return Ember.get(s, "email");
          }));
        }
        if (Ember.isArray(b.get("shares"))) {
          budgetLines.shares.addObjects(b.get("shares").filter(function (s) {
            return Ember.get(s, "access") !== "owner";
          }).mapBy("user.email"));
        }
      });
      return budgetLines;
    }),
    isComplete: Ember.computed("guideMissions", function () {
      var complete = true;
      var guideMissions = this.get("guideMissions");
      guideMissions.groups.forEach(function (group) {
        group.get("missions").forEach(function (mission) {
          if (mission.active && mission.status === "incomplete") {
            complete = false;
          }
        });
      });
      return complete;
    })
  });
});